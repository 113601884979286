import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
// import { AuthService } from './auth-service.service';
import { SweetalertService } from './sweetalert.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
    private router: Router,
    // private authService: AuthService,
    private sweetAlert: SweetalertService) { }

  public handleHttpErrorResponse(error: any) {

    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        
      } else {
       
        switch (error.status) {
          case 400: {
            this.sweetAlert.alertManualClose("Invalid Entry", "Kindly enter valid values");
            break;
          }
          case 401: {     //login
            //this.authService.logout(false);
            this.router.navigateByUrl("/login");
            break;
          }
          case 403: {    //forbidden
            //this.authService.logout(false);
            this.router.navigateByUrl("/login");
            break;
          }
        }
      }
    } else {
      alert("An error occured, please contact the system admin.");
    }
  }


  public handleApplicationError(error: any) {
    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) { } 
      else {
        
        switch (error.status) {
          case 400:  {    //login
            if(this.router.url.toLowerCase().indexOf("newpatient") >= 0)
            {
              this.sweetAlert.alertManualClose("Invalid Entry", "Kindly ensure to date format as (dd/mm/yyyy) and valid Identity Number");
            }
            else{
              this.sweetAlert.alertManualClose("Invalid Entry", "Kindly enter valid values");
            }
            break;
          }
          case 401: {          
            this.sweetAlert.alertManualClose("unAutherized", "Verify the credentials");
            break;
          }
          case 403:   {  //forbidden
           // this.authService.logout(false);
            this.router.navigateByUrl("/login");
            this.sweetAlert.alertManualClose("Login failed", "Invalid user name or password");
            break;
          }
          case 404:   {  //forbidden              
            this.sweetAlert.alertManualClose("Service Down", "Try after sometime or contact Admin if the issue persist");
            break;
          }
          case 500:   {  //forbidden              
            this.sweetAlert.alertManualClose("Service Down", "Try after sometime or contact Admin if the issue persist");
            break;
          }
        }
      }
    } else {
      
      this.sweetAlert.alertManualClose("Error occured", "An error occured, please contact the system admin.");
    }
  }
}
