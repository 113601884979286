import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs/internal/Subscription';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { UserModel } from 'src/app/models/user';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { delay, takeLast } from 'rxjs/operators';



declare var $: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  @ViewChild('codeBox1') codeBox1;

  public DFA: FormGroup;
  public loginForm: FormGroup;
  public submitted: boolean = false;
  public btnsubmit: boolean = false;
  public loginAuth_sub: Subscription;
  public backgroundImage: string = "assets/images/login_background.jpg";
  public isLoadedFrmDB: boolean = false;
  public backgroundColor: string = "rgb(59 80 81)";
  public btncolor: string = "#F2731D";
  public btncolorSecondary: string = ""
  public sidePanelColor: string = "#333537"
  public capsOn: boolean = false;
  public userColl: Array<UserModel> = [];

  constructor(
    private domSanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private router: Router,
    private sweetAlertService: SweetalertService,
    private cd: ChangeDetectorRef,
    private errorService: ErrorHandlerService,
    private configService: ConfigurationService) { }

  ngOnDestroy(): void {



  }

  ngOnInit() {

    if (localStorage.getItem("userId") != null && localStorage.getItem("userId") != "") {
      this.router.navigateByUrl('hc');
    }
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remberMe: [false]
    });

    // this.getAppUsers();


  }

  public get f() { return this.loginForm.controls; }

  public login() {

    this.submitted = true;
    this.btnsubmit = true;
    if (this.loginForm.invalid) {
      this.btnsubmit = false;
      return;
    }
    
    this.configService.users_get().subscribe(
      successResponse => {
        this.userColl = successResponse;
        var currentUser = this.userColl.find(e => e.login == String(this.f.username.value).trim() && e.password == String(this.f.password.value).trim())

        if (currentUser != null && currentUser != undefined && currentUser.userId > 0) {
          localStorage.setItem("userId", currentUser.userId.toString());
          localStorage.setItem("userCode", currentUser.userCode.toString());
          localStorage.setItem("userName", currentUser.userName.toString());
          this.router.navigateByUrl('hc');
        }
        else {
          this.sweetAlertService.showtoaster('Invalid Credential');
          this.delay(3000).then(any => {
            location.reload();
          });
        }
      },
      error => { this.errorService.handleApplicationError(error) }
    );



    // this.loginAuth_sub = this.authService.login(String(this.f.username.value).trim(), String(this.f.password.value).trim(), false, isRememberMe)
    //   .pipe(first())
    //   .subscribe(
    //     data => {

    //       this.storedUserInfo = data;
    //       if (this.storedUserInfo.token != null && this.storedUserInfo.token.isDFA) {
    //         this.verifyDFAAuth();
    //       }
    //       else {
    //         this.AutheticatedUser();
    //       }

    //     },
    //     error => {
    //       this.authService.logout(false);
    //       this.loginForm.get('password').setErrors({ required: true });
    //       this.btnsubmit = false;
    //     });
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }

  private getAppUsers() {
    this.configService.users_get().subscribe(
      successResponse => {
        this.userColl = successResponse;
      },
      error => { this.errorService.handleApplicationError(error) }
    );
  }


  public transform(url) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  public checkCapsLock(e) {
    var myKeyCode = e.which ? e.which : (e.keyCode ? e.keyCode : (e.charCode ? e.charCode : 0));
    var myShiftKey = e.shiftKey || (e.modifiers && (e.modifiers & 4));
    var charStr = String.fromCharCode(myKeyCode);
    if (((myKeyCode >= 65 && myKeyCode <= 90) && !myShiftKey) || ((myKeyCode >= 97 && myKeyCode <= 122) && myShiftKey)) {
      this.capsOn = true;
    }
    else {
      this.capsOn = false;
    }
  }






}