import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public userId: string = "";
  public userName: string = "";
  public header:boolean = false;
  public today = Date.now();
  constructor( private router: Router,) {
    setInterval(() => {
      this.today = Date.now();
    }, 100);
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
   
  }
 
}
