<div class="login-page">
  <div [hidden]="isLoadedFrmDB" class="login-page-bg" [ngStyle]="{backgroundImage:'url(' + backgroundImage + ')'}">
  </div>
  <img [hidden]="!isLoadedFrmDB" class="login-page-bg" id="imgbackground" [src]=transform(backgroundImage)
    data-src={{backgroundImage}}>
  <div class="login-container">
    <div class="row" id="rowlogin">

      <div class="col-md-7 col-12 login-slider">
        <div class="divcompanylogo mb-2">
          <div id="companyLogo"><img class="companyLogoImg" src="../../../assets/images/SmartLablogo.jpg" /></div>
        </div>
        <div id="sliderContainer">
          <div class="carousel slide" data-ride="carousel" data-interval="3000">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="assets/images/slider_1.svg" class="img-fluid" alt="login page" />
                <div class="px-4 mt-4">
                  <!-- <h4>Any where, Any time</h4>
                  <p>Can create, monitor patients.</p> -->
                </div>
              </div>
              <div class="carousel-item">
                <img src="assets/images/image_2.png" class="img-fluid" alt="login page" />
                <div class="px-4 mt-4">
                  <!-- <p>Can create, track tickets.</p> -->
                </div>
              </div>
              <div class="carousel-item">
                <img src="assets/images/image_3.png" class="img-fluid" alt="login page" />
                <div class="px-4 mt-4">
                  <!-- <p>Check Statistics of Doctors, Nurses and many more.</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="login-powered-by">
          <img class="synapses-logo" src="assets/img/synapses_logo.svg" />
          <span class="pwdby">Powered by</span>
          <span class="cloud-logo"><img src="assets/img/cloud_solutions_logo.svg" /></span>
        </div> -->
      </div>

      <div class="col-md-5 col-12 login-form" [ngStyle]="{background:backgroundColor}" >
        <h3 id="divLoginh3">Login</h3>
        <!-- <h6 id="divLoginh6">Login to your account</h6> -->
        <form [formGroup]="loginForm" (ngSubmit)="login()">
          <fieldset>
            <div class="form-group">
              <label class="">User Name</label>
              <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                formControlName="username" [class.invalid-entry]="submitted && f.password.errors" tabindex="1">
            </div>
            <!-- [ngClass]="{ 'is-invalid': submitted && f.username.errors }" -->
            <!-- [ngClass]="{ 'is-invalid': submitted && f.password.errors  -->
            <div class="form-group mt-2" style="margin-bottom: 0px;">
              <label class="">Password</label>
              <label class="pull-right" *ngIf="capsOn"><span class="badge badge-danger">Caps Lock On</span></label>
              <input type="password" class="form-control" formControlName="password"
                [class.invalid-entry]="submitted && f.password.errors" (keypress)="checkCapsLock($event)" tabindex="2"
                appPassword>
              <div *ngIf="submitted && (f.password.errors)" class="invalid-cst-feedback">
                Invalid User Name or Password</div>
            </div>
           
            <div class="form-footer mt-3 ">
              <button type="submit" class=" btn btn-success" style="color:white ; float: right;" id="loginBtn"
                tabindex="3">&nbsp;Login&nbsp;</button>
             
            </div>
          </fieldset>
        </form>



      </div>
      

    </div>
  </div>

</div>