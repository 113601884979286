<nav class="navbar navbar-expand-lg navbar-dark bg-light bg-gradient" style="line-height: 1;">
    <div class="container-fluid">
        <div class=" col-lg-12 col-md-12 col-12 text-center">
            <h3> <span class="badge bg-primary" title="Risalakkaalam">Risala Live Board</span></h3>
        </div>
        <div class="col-lg-12 col-md-12 col-12 text-center">
           <h4> <span class="badge bg-primary">  {{ today | date:'HH:mm:ss' }}</span></h4>
        </div>
    </div>
    

</nav>
<hr style="margin-top: -6px;">