import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHandlerService } from './http-handler.service';
@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor( private httpHandler: HttpHandlerService) { }

 
  public users_get(): Observable<Array<any>> {
    return this.httpHandler.getJSONData('assets/app-data/users.json');
  }

  
  public api_config_get(): Observable<Array<any>> {
    return this.httpHandler.getJSONData('assets/app-data/api-config.json');
  }


  // public competition_items_get(searchObj: ItemMasterModel): Observable<Result> {
  //   return this.httpHandler.service_post('Configuration/Competition_Items_Get', searchObj).pipe(map(
  //     data => { return <Result>data; }
  //   ));
  // }
  
}
