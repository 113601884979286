import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { ExternalService } from 'src/app/services/external.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { DatePickerComponent, IDayCalendarConfig } from 'ng2-date-picker';
import * as moment from 'moment';
import { forkJoin } from "rxjs";
import '../../../assets/email/smtp.js';
import { DEF_CONF } from 'src/app/models/datepicker.model.js';
declare var Email: any;

@Component({
    selector: 'app-hc',
    templateUrl: './home-collection.component.html',
    styleUrls: ['./home-collection.component.scss']
})
export class HomeCollectionComponent implements OnInit {
    @ViewChild("datePicker") public datePicker: DatePickerComponent;
    public patientGender: string = 'Male';
    public patientInfoForm: FormGroup;
    public testForm: FormGroup;
    public submitForm: FormGroup;
    public maxDigit: number;
    public memberInfo: any = {};
    public labTestNameColl: Array<any> = [];
    public requestNoColl: Array<any> = [];
    public dynamicArray: Array<any> = [];
    public testList: Array<any> = [];
    public userCode: number = 0;
    public totalAmount: number = 0;
    public submitApiUrl: string = "https://api.smartlab.sa/post_hc/";
    public loading: boolean = false;
    public enableCards: boolean = false;

    public daytimePickerConfig = <IDayCalendarConfig>{
        ...DEF_CONF,
        format: "DD/MM/YYYY hh:mm A",
        min: this.getCurrentDate_ddMMYYYY(),

    };

    constructor(
        private formBuilder: FormBuilder,
        private sweetAlertService: SweetalertService,
        private commService: CommonService,
        private configService: ConfigurationService,
        private externalService: ExternalService,
        private errorService: ErrorHandlerService,
    ) {


    }

    ngOnInit() {
        this.InitiateForms();
        this.userCode = +localStorage.getItem("userCode");
        document.getElementById('membershipNo').focus();
    }
    public getCurrentDate_ddMMYYYY(): string {

        let d1: Date = new Date(Date.now());
        d1 = this.DatetimeCorrection(d1);
        return moment(d1).format("DD/MM/YYYY hh:mm");
    }

    public DatetimeCorrection(inputDateVal: Date) {
        var userTimezoneOffset = inputDateVal.getTimezoneOffset() * 110000;
        return new Date(inputDateVal.getTime() - userTimezoneOffset);
    }

    public getCorrectDate(dateVal): Date {
        if (dateVal != null && dateVal.indexOf('/') > 0) {
            let arrDate = dateVal.split('/');
            var dt = new Date(arrDate[1] + "/" + arrDate[0] + "/" + arrDate[2]);
            return this.DatetimeCorrection(dt);
        }
    }


    InitiateForms() {

        this.dynamicArray = [];
        this.testForm = this.formBuilder.group({
            labTestId: [0, Validators.required],
            type: [null]
        });

        this.submitForm = this.formBuilder.group({
            requestNo: [null, Validators.required],
            hcDate: [null, Validators.required],
            address: [null],
            remarks: [' ']
        });

        this.patientInfoForm = this.formBuilder.group({
            membershipNo: [''],
            patientName: [''],
            identityNumber: [''],
            dateOfBirth: [null],

            identityType: [''],
            healthId: [''],
            nationality: [''],

            age: [''],
            gender: [null],
            bloodGroup: [null],

            hcStartDate: [null],
            mobileNo: [''],
            email: [''],
        });

    }



    public clearPatientSearch() {
        this.patientInfoForm.reset();
        this.testForm.reset();
        this.submitForm.reset();
        this.submitForm.controls['requestNo'].setValue(null);
        this.dynamicArray = [];
        this.memberInfo = {};
        this.enableCards = false;
        this.loading = false;
    }


    public NumberFieldValidate(event, length) {
        var character = String.fromCharCode(event.keyCode).toLowerCase();
        var fullString = event.target.value + character;
        if (this.commService.Number_specialchar_valid(event, length)) {
            this.maxDigit = Number(fullString);
            if (String(this.maxDigit) == "NaN") {
                this.maxDigit = null;
                return false;
            }
            else
                return true;
        }
        else return false;
    }

    public searchPatient() {
        //this.sendEmailNotification();
        this.loading = true;
        this.testForm.reset();
        this.submitForm.reset();
        this.submitForm.controls['requestNo'].setValue(null);
        this.dynamicArray = [];
        this.memberInfo = {};
        let req: any = {};
        let labreq: any = {};
        let memberRequests: any = {};
        let rawData: any = this.patientInfoForm.getRawValue();
        this.configService.api_config_get().subscribe(
            successResponse => {
                req = {
                    membershipNo: rawData.membershipNo,
                    url: successResponse[0].apiBubaMemberInfoGet.url,
                    XIBMClientId: successResponse[0].apiBubaMemberInfoGet.XIBMClientId,
                    XIBMClientSecret: successResponse[0].apiBubaMemberInfoGet.XIBMClientSecret
                };
                labreq = {
                    url: successResponse[1].apiLabTestDetailsGet.url,
                    token: successResponse[1].apiLabTestDetailsGet.token
                }
                memberRequests = {
                    membershipNo: rawData.membershipNo,
                    url: successResponse[3].apiBubaMemberRequestsGet.url,
                    XIBMClientId: successResponse[3].apiBubaMemberRequestsGet.XIBMClientId,
                    XIBMClientSecret: successResponse[3].apiBubaMemberRequestsGet.XIBMClientSecret
                }
                // this.submitApiUrl = successResponse[2].apiSubmitDetailsPost.url;
                forkJoin([
                    this.externalService.bupa_patient_get(req),
                    this.externalService.bupa_patient_requests_get(memberRequests)
                ]).subscribe(
                    suceesResponse => {
                        if (successResponse != null) {
                            if (suceesResponse[0]?.detail != null) {
                                this.enableCards = true;
                                this.memberInfo = suceesResponse[0].detail[0];
                                this.memberInfo.mbrAge = this.calculateAge(new Date(this.memberInfo.memberDOB));
                                this.bindControls(this.memberInfo);

                                this.externalService.livehealth_testdetails_get(labreq).subscribe(
                                    suceesResponse => {
                                        if (suceesResponse.testList != undefined) {
                                            this.labTestNameColl = suceesResponse.testList;
                                            this.loading = false;
                                        }
                                        else {
                                            this.sweetAlertService.showtoaster('Invalid Test List , Contact Admin');
                                            this.loading = false;
                                            this.enableCards = false;
                                        }

                                    },
                                    error => {
                                        this.errorService.handleApplicationError(error);
                                        this.loading = false;
                                    }
                                );

                                if (suceesResponse[1]?.patientDetails != null) {
                                    this.requestNoColl = suceesResponse[1]?.patientDetails;
                                }

                            }
                            else {
                                this.sweetAlertService.showtoaster('Membership number not found');
                                this.loading = false;
                            }
                        }
                        else {
                            this.loading = false;
                        }

                    },
                    error => {
                        this.errorService.handleApplicationError(error);
                        this.loading = false;
                    }
                );

                this.externalService.bupa_patient_get(req).subscribe(
                    suceesResponse => {
                        if (successResponse != null) {
                            if (suceesResponse.detail != null) {
                                this.enableCards = true;
                                this.memberInfo = suceesResponse.detail[0];
                                this.bindControls(this.memberInfo);

                                this.externalService.livehealth_testdetails_get(labreq).subscribe(
                                    suceesResponse => {
                                        this.labTestNameColl = suceesResponse.testList;
                                        this.loading = false;
                                    },
                                    error => {
                                        this.errorService.handleApplicationError(error);
                                        this.loading = false;
                                    }
                                );

                            }
                            else {
                                this.sweetAlertService.showtoaster('Membership number not found');
                                this.loading = false;
                            }
                        }
                        else {
                            this.loading = false;
                        }

                    },
                    error => {
                        this.errorService.handleApplicationError(error);
                        this.loading = false;
                    }
                );
            },
            error => {
                this.errorService.handleApplicationError(error);
                this.loading = false;
            }
        );

    }
    private bindControls(data: any) {
        this.patientInfoForm.controls['patientName'].setValue(data.memberName);
        this.patientInfoForm.controls['identityNumber'].setValue(data.memberID);
        this.patientInfoForm.controls['dateOfBirth'].setValue(data.memberDOB);
        this.patientInfoForm.controls['age'].setValue(data.mbrAge);
        this.patientInfoForm.controls['gender'].setValue(data.gender);
        this.patientGender = data.gender;
        this.patientInfoForm.controls['nationality'].setValue(data.countryName);
        this.patientInfoForm.controls['mobileNo'].setValue(data.mobileNo);
        this.patientInfoForm.controls['email'].setValue(data.email);
        this.patientInfoForm.controls['hcStartDate'].setValue(data.startDate);

    }

    public appendTest() {
        let rawData: any = this.testForm.getRawValue();
        if (rawData.labTestId != null && rawData.labTestId != undefined && rawData.labTestId > 0) {
            if (this.dynamicArray.some(d => d.testID == rawData.labTestId)) {
                this.sweetAlertService.showtoaster('Selected lab test already added');
            }
            else {
                let testItem = this.labTestNameColl.find(e => e.testID == rawData.labTestId);
                testItem.slNo = this.dynamicArray.length + 1;
                this.dynamicArray.push(testItem);
                this.testForm.reset();
            }

        }

    }

    public calculateAge(dob: Date) {
        let timeDiff = Math.abs(Date.now() - dob.getTime());
        let age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
        return age;
    }

    private calculateTotalAmount() {
        this.totalAmount = 0;
        this.testList = [];
        this.dynamicArray.forEach(item => {
            this.totalAmount = this.totalAmount + +item.testAmount;
            this.testList.push({
                testID: item.testID,
                testCode: item.testCode,
                integrationCode: item.integrationCode,
                dictionaryId: item.dictionaryId
            });

        });
    }
    public removeTest(item: any) {
        if (item != null && item != undefined) {
            this.dynamicArray = this.dynamicArray.filter(e => e.testID != item.testID);
            if (this.dynamicArray.length > 0) {
                this.dynamicArray.forEach(e => {
                    if (e.slNo > item.slNo) {
                        e.slNo = e.slNo - 1;
                    }
                });
            }
        }

    }

    public submitHomeCollection() {
        this.loading = true;
        this.calculateTotalAmount();

        let rawData: any = this.patientInfoForm.getRawValue();
        let subData: any = this.submitForm.getRawValue();

        if (subData.hcDate == null || subData.hcDate == '') {
            this.sweetAlertService.showtoaster('Select Home Collection Date');
            this.loading = false;
            return;
        }
        else if (subData.address == null) {
            this.sweetAlertService.showtoaster('Ente the Address');
            this.loading = false;
            return;
        }
        // console.log(subData.hcDate);
        // console.log(this.getCorrectDate(subData.hcDate).toJSON());
        // console.log(this.getCorrectDate(subData.hcDate).toJSON().replace(".000Z", "Z"));

        let req = {
            mobile: rawData.mobileNo.replace('/00966/gi', '0') ? rawData.mobileNo.replace("00966", "0") : rawData.mobileNo,
            email: rawData.email,
            designation: "",
            fullName: rawData.patientName,
            age: rawData.age,
            gender: rawData.gender,
            labPatientId: rawData.membershipNo,
            dob: moment(rawData.dateOfBirth).format("YYYY-MM-DD"),
            nationality: rawData.nationality,
            nationalIdentityNumber: rawData.identityNumber,
            isHomecollection: 1,
            homeCollectionDateTime: this.getCorrectDate(subData.hcDate).toJSON().replace(".000Z", "Z"),
            // homeCollectionDateTime: rawData.hcStartDate,
            address: subData.address,
            city: "",
            area: subData.address,
            patientType: "",
            billDetails: {
                billDate: "",
                emergencyFlag: 0,
                totalAmount: this.totalAmount,
                advance: 0,
                paymentType: "Credit",
                referralName: "SELF",
                otherReferral: "",
                orderNumber: subData.requestNo,
                organisationName: localStorage.getItem("userName"),
                organizationIdLH: this.userCode,
                comments: subData.remarks == null ? '' : subData.remarks,
                testList: this.testList,
                paymentList: [{
                    paymentType: "Credit",
                    paymentAmount: "",
                    issueBank: ""
                }]
            }
        };      
        this.externalService.livehealth_registerbill_post(this.submitApiUrl, req).subscribe(
            suceesResponse => {
                if (suceesResponse.code == 200) {
                    this.sweetAlertService.alertAutoClose('Request Submitted');
                    this.clearPatientSearch();
                    this.loading = false;
                }
                else {
                    this.sweetAlertService.showToast(suceesResponse.Message);
                    this.loading = false;
                }
            },
            error => {
                if (error.status == 200) {
                    this.sweetAlertService.alertAutoClose('Request Submitted');
                    this.clearPatientSearch();
                    this.loading = false;
                }
                else {
                    this.errorService.handleApplicationError(error);
                    this.loading = false;
                }

            }
        );
        debugger

    }

    public sendEmailNotification() {
        Email.send({
            Host: 'smtp.elasticemail.com',
            Username: 'smartlab.hc@gmail.com',
            Password: 'C65FDC601946F06355523F2053DD303B7242',
            To: 'albusthan@gmail.com',
            From: 'smartlab.hc@gmail.com',
            Subject: 'New Home Collection Request',
            Body: `
            <br/> <b>New Home collection request submitted and bille generated</b> `
        }).then(message => { alert(message); });


    }

}
