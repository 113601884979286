


  <div class="row" style="padding-left: 10px;padding-right: 10px;">    
 

        <div class="row text-center">
          <h1>  Stay tuned.... </h1>
        </div>



      <!-- <div  class="col-xl-3 col-lg-3 col-md-3 col-12 mt-2"*ngFor="let item of scoreColl; let i = index;">
     
          <div class="card {{item.class}}">
              <div class="card-statistic-3 p-4">
                  <div class="card-icon card-icon-large"><i class="fas fa-bar-chart"></i></div>
                  <div class="mb-4">
                      <h5 class="card-title mb-0">{{item.Entity}}</h5>
                  </div>
                  <div class="row align-items-center mb-2 d-flex">
                      <div class="col-8">
                          <h2 class="d-flex align-items-center mb-0">
                              <b>{{item.TargetAch}}%</b>
                          </h2>
                      </div>
                      <div class="col-4 text-right" *ngIf="item.TargetAch < 100">
                          <span><b>{{(100-item.TargetAch).toFixed(2)}}% Remaining <i class="fa fa-arrow-right"></i></b></span>
                      </div>
                  </div>
                  <div class="progress mt-1 " data-height="8" style="height: 8px;">
                      <div class="progress-bar l-bg-orange" role="progressbar" data-width="{{item.TargetAch}}%" aria-valuenow="{{item.TargetAch}}" aria-valuemin="0" aria-valuemax="100" style="width: {{item.TargetAch}}%;"></div>
                  </div>
              </div>
          </div>
      </div> -->







      <!-- <div class="col-xl-3 col-lg-6">
          <div class="card l-bg-orange-dark">
              <div class="card-statistic-3 p-4">
                  <div class="card-icon card-icon-large"><i class="fas fa-line-chart"></i></div>
                  <div class="mb-4">
                      <h5 class="card-title mb-0">Revenue Today</h5>
                  </div>
                  <div class="row align-items-center mb-2 d-flex">
                      <div class="col-8">
                          <h2 class="d-flex align-items-center mb-0">
                              $11.61k
                          </h2>
                      </div>
                      <div class="col-4 text-right">
                          <span>2.5% <i class="fa fa-arrow-up"></i></span>
                      </div>
                  </div>
                  <div class="progress mt-1 " data-height="8" style="height: 8px;">
                      <div class="progress-bar l-bg-cyan" role="progressbar" data-width="25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="width: 25%;"></div>
                  </div>
              </div>
          </div>
      </div> -->
  </div>





