import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { interval } from 'rxjs';
import { ExternalService } from 'src/app/services/external.service';


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})


export class DashboardComponent implements OnInit {
    public scoreColl: Array<any> = [];
    
    constructor(
        private externalService: ExternalService,
    ) {
      
    }

    ngOnInit() {
        // interval(10000).subscribe(() => {
        //     this.getNationalScoreboardLive();
        // });

        // this.getNationalScoreboardLive();
    }

    public getNationalScoreboardLive() {
        this.externalService.national_scoreboard_get({}).subscribe(
            (suceesResponse: any) => {
                if (suceesResponse != null) {
                    this.scoreColl = suceesResponse.filter(e=>e.Entity != 'CENTRAL SECTOR');
                    this.scoreColl.forEach(item => {
                        if (item.TargetAch > 95) {
                            item.class = "l-bg-green-dark";
                        }
                        else if (item.TargetAch > 80) {
                            item.class = "l-bg-blue-dark";
                        }
                        else if (item.TargetAch > 60) {
                            item.class = "l-bg-yelllow-dark";
                        }
                        else if (item.TargetAch > 40) {
                            item.class = "l-bg-orange-dark";
                        }
                        else if (item.TargetAch > 25) {
                            item.class = "l-bg-red-dark";
                        }
                    });

                    


                }
                else {

                }

            },
            error => {

            }
        );
    }
}
