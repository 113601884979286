import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceModel } from '../models/device.model';

declare var $;

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private sanitized: DomSanitizer) { }

  removeItem(coll: Array<any>, i: number) {
    var index = coll.indexOf(coll[i]);
    coll.splice(index, 1);
    coll.forEach(function (item, index) {
      item.hierarchy = index + 1;
    });

  }

  public removeSpaces(control: AbstractControl) {
    if (control && control.value!=null && control.value.replace != undefined && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }

 

 

  public getDateTime(): string {

    return new Date().toISOString().split('T')[0] + "T23:59"
    // let d1 = new Date(Date.now());
    // d1.setHours(d1.getHours() + 3);
    // return d1.toISOString().slice(0, 16);

  }

  public compareDate(date1: Date, date2: Date): number {
    // With Date object we can compare dates them using the >, <, <= or >=.
    // The ==, !=, ===, and !== operators require to use date.getTime(),
    // so we need to create a new instance of Date with 'new Date()'
    let d1 = new Date(date1); let d2 = new Date(date2);

    // Check if the dates are equal
    let same = d1.getTime() === d2.getTime();
    if (same) return 0;

    // Check if the first is greater than second
    if (d1 > d2) return 1;

    // Check if the first is less than second
    if (d1 < d2) return -1;
  }

  public compareDateOnly(date1: Date, date2: Date): number {

    let d1 = new Date(date1); let d2 = new Date(date2);

    // Check if the dates are equal
    let same = d1.getDate() === d2.getDate();
    if (same) return 0;

    // Check if the first is greater than second
    if (d1 > d2) return 1;

    // Check if the first is less than second
    if (d1 < d2) return -1;
  }

  public checkDateforTable(e): string {
    let currentDate = new Date(Date.now());
    let tempDate = this.compareDateOnly(e, currentDate);
    if (tempDate === 0) {
      return "new";
    }
    else if (tempDate === -1) {
      return "old";
    }

  }

  public getCurrentDate_ddMMYYYY(): string {

    let d1: Date = new Date(Date.now());
    d1 = this.DatetimeCorrection(d1);
    return moment(d1).format("DD/MM/YYYY");
  }
  public getCurrentDate_MMYYYY(): string {

    let d1: Date = new Date(Date.now());
    d1 = this.DatetimeCorrection(d1);
    return moment(d1).format("MM/YYYY");
  }

  public getCurrentDate(): string {

    let d1: Date = new Date(Date.now());
    d1 = this.DatetimeCorrection(d1);
    return d1.toISOString().slice(0, 16);
  }

  public getadjustedCurrentDate(hours:number): string {

    let d1: Date = new Date(Date.now());
    d1 = this.DatetimeCorrection(d1);
    d1.setHours(d1.getHours() + hours);
    return moment(d1).format("DD/MM/YYYY");
  }

  public getCorrectDate(dateVal):Date{
    if(dateVal!=null && dateVal.indexOf('/')>0){
      let arrDate = dateVal.split('/');
        var dt= new Date(arrDate[1]+"/"+arrDate[0]+"/"+arrDate[2]);
        return this.DatetimeCorrection(dt);
    }
  }

  public getDisplayCorrectDate(dateVal):Date{
    if(dateVal!=null && dateVal.indexOf('/')>0){
      let arrDate = dateVal.split('/');
        var dt= new Date(arrDate[1]+"/"+arrDate[0]+"/"+arrDate[2]);
        return dt;
    }
  }

  public getCorrectDateHyphen(dateVal):Date{
    if(dateVal!=null && dateVal.indexOf('-')>0){
      let arrDate = dateVal.split('-');
        var dt= new Date(arrDate[1]+"/"+arrDate[0]+"/"+arrDate[2]);
        return this.DatetimeCorrection(dt);
    }
  }

  public formatDate(str, type: string) {
    if (type == "") {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2),
        tm = (date.getHours() + ":" + date.getMinutes());
      return [mnth,day, date.getFullYear()].join("/") + " " + tm;

    }
    else {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2),
        tm = (date.getHours() + ":" + date.getMinutes());
      return [date.getFullYear(), mnth, day].join("-") + " " + tm;

    }

  }

  public days_between(date1, date2) {

    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);

}

public ConvertToDateHoursMin(datetime) {
  let d1:any = new Date(datetime);
  let now:any = new Date();
  var sec_num = (now - d1) / 1000;
  let days:any    = Math.floor(sec_num / (3600 * 24));
  let hours:any   = Math.floor((sec_num - (days * (3600 * 24)))/3600);
  let minutes:any = Math.floor((sec_num - (days * (3600 * 24)) - (hours * 3600)) / 60);
  let seconds:any = Math.floor(sec_num - (days * (3600 * 24)) - (hours * 3600) - (minutes * 60));

  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  //return  days+':'+ hours+':'+minutes+':'+seconds;
  //58 Days, 19 Hr, 9 Min
  return  days+' '+'Days,'+' '+ hours+' '+'Hr,'+' '+minutes+' '+'Min';
}

public PeriodofStay(datetime) {
  let d1:any = new Date(datetime);
  let now:any = new Date();
  var sec_num = (now - d1) / 1000;
  let days:any    = Math.floor(sec_num / (3600 * 24));
  let hours:any   = Math.floor((sec_num - (days * (3600 * 24)))/3600);
  let minutes:any = Math.floor((sec_num - (days * (3600 * 24)) - (hours * 3600)) / 60);
  let seconds:any = Math.floor(sec_num - (days * (3600 * 24)) - (hours * 3600) - (minutes * 60));

  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  //return  days+':'+ hours+':'+minutes+':'+seconds;
  //58 Days, 19 Hr, 9 Min
  if(days==0)
  {
    return  hours+'H '+minutes+'m';
  }
  else
  {
    return  days+'D, '+ hours+'H';//,'+' '+minutes+' '+'Min';
  }
}

public ConvertDateToDays(datetime) {
  let d1:any = new Date(datetime);
  let now:any = new Date();
  var sec_num = (now - d1) / 1000;
  let days:any    = Math.floor(sec_num / (3600 * 24));
  return  days;
}

public ConvertFlowSheetDateTime(time){
  let curretndate = new Date();
  let hrs = String(time).split(":");
  let dt = new Date(curretndate.getUTCFullYear(), curretndate.getUTCMonth(), curretndate.getUTCDate(), parseInt(hrs[0]), parseInt(hrs[1]), 0, 0);
  dt=this.DatetimeCorrection(dt);// to avoid timezone time mismatch
  return dt
}

public ConvertFlowSheetTime(time){
  let captime =  new Date(time);
  let captimehrString = (captime.getHours().toString().length==1)?"0"+captime.getHours().toString():captime.getHours().toString();
  let captimeminString = (captime.getMinutes().toString().length==1)?"0"+captime.getMinutes().toString():captime.getMinutes().toString();
  return captimehrString+":"+captimeminString;
}

public DatetimeCorrection(inputDateVal:Date){
  var userTimezoneOffset = inputDateVal.getTimezoneOffset() * 60000;

    return new Date(inputDateVal.getTime() - userTimezoneOffset);
}

public Number_specialchar_valid(event,length:number):boolean {
    var character = String.fromCharCode(event.keyCode).toLowerCase();
    var fullString = event.target.value + character;
    if(fullString.length <= length){ 
      if(character == 'e' || character == '+' || character == '-' || character == '.') {
        return false;
      }
      else return true;
    }
    else{
      return false;
    }
  }

  public Decimal_specialchar_valid(event,decimalPlaces) {
    var character = String.fromCharCode(event.keyCode).toLowerCase();
    var fullString = event.target.value+character;
    var valLength = fullString.length - fullString.indexOf('.');
    if(character == 'e' || character == '+' || character == '-') {
      return false;
    }
    else if(valLength-1 > decimalPlaces){
      return false;
    }
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }  
  }

  public getSafeHTML(e) {
    
    return e!=undefined ? this.sanitized.bypassSecurityTrustHtml(e) : null ;
  }

  public PutLocalStorage(key:string,data) {
    
    localStorage.setItem(key,JSON.stringify(data)) ;
  }

  public GetLocalStorage(key:string) {
    
    return JSON.parse(localStorage.getItem(key));
  }

  public RemoveLocalStorage(key:string) {
    
    return localStorage.removeItem(key);
  }

  public AssignAlertColors(key:string) :string {
    var mods = "";

    switch(key){
      case "DVC":{
        mods = "<span title='Devices' class='badge tag-dvc mr-1'>"+key+"</span>";
        break;}
      case "RSP":{
        mods = "<span title='Ventilation' class='badge badge-danger mr-1'>"+key+"</span>";
        break;}
      case "VTL":{
        mods = "<span title='Vitals' class='badge tag-vtl mr-1'>"+key+"</span>";
        break;}
      case "PRS":{
        mods = "<span title='Pressors' class='badge tag-prs mr-1'>"+key+"</span>";
        break;}
      case "LAB":{
        mods = "<span title='Lab' class='badge tag-lab mr-1'>"+key+"</span>";
        break;}
      case "MIC":{
        mods = "<span title='Micorobiology' class='badge tag-mic mr-1'>"+key+"</span>";
        break;}
      case "RAD":{
        mods = "<span title='Radiology' class='badge tag-rad mr-1'>"+key+"</span>";
        break;}
      case "ABG":{
        mods = "<span title='ABG Monitoring' class='badge tag-abg mr-1'>"+key+"</span>";
        break;}
      case "RBS":{
        mods = "<span title='RBS' class='badge tag-rbs mr-1'>"+key+"</span>";
        break;}
      case "GCS":{
        mods = "<span title='GCS' class='badge tag-gcs mr-1'>"+key+"</span>";
        break;}
    }

    return mods;
  }

  public CheckScreenSize():boolean{
    let device:DeviceModel = {
      width : window.screen.width,
      height : window.screen.height,
    }
    if (window.screen.width < 900) {
      
      device.issmallDevice = true;
    }
    else {
      device.issmallDevice = false;
    }   

    localStorage.setItem('userdevice', JSON.stringify(device));

    return device.issmallDevice;
  }
  
  public GetDeviceInfo():DeviceModel{
     return this.GetLocalStorage('userdevice');
  }
}
