import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpHandlerService } from './http-handler.service';



@Injectable({
  providedIn: 'root'
})
export class ExternalService {
  private slotsMessage = new Subject<any>();
  constructor(private httpHandler: HttpHandlerService) { }

  public visit_dashboard_get(searchObj: any): Observable<any> {
    return this.httpHandler.service_post('VirtualVisit/Visits_Dashboard_Get', searchObj).pipe(map(
      data => { return <any>data; }
    ));
  }

  public national_scoreboard_get(searchObj: any): Observable<any> {
    return this.post_request('Risala/NationalScoreboard_Get', searchObj).pipe(map(
      data => { return <any>data; }
    ));
    
  }

  private post_request(url: string, postParam: any): Observable<any> {
    let headers = { include: "true" };
    return this.httpHandler.post(`${environment.apiEndPoint}` + url, postParam, headers).pipe(map(
      data => { return <any>data; }
    ));
  }


  public dependant_manage(content: Array<any>): Observable<any> {

    return this.httpHandler.service_post('VirtualVisit/Dependant_Manage', content).pipe(map(
      data => { return data; }
    ));
  }

  public visitor_manage(content: any): Observable<any> {
    return this.httpHandler.service_post('VirtualVisit/Patient_Visitor_Manage', content).pipe(map(
      data => { return data; }
    ));
  }
  public bupa_patient_get(content: any): Observable<any> {    

    if (content.url != null) {     

      return this.httpHandler.external_service_bupa_memberinfo_get(content).pipe(map(
        
        data => {
           return data; }
      ));
    }
    else {
      return of({});;
    }
  }

  public bupa_patient_requests_get(content: any): Observable<any> {    

    if (content.url != null) {     

      return this.httpHandler.external_service_bupa_member_requests_get(content).pipe(map(
        data => { return data; }
      ));
    }
    else {
      return of({});;
    }
  }


  public livehealth_testdetails_get(content: any): Observable<any> {    

    if (content.url != null) {     
      return this.httpHandler.external_service_livehealth_testdetails_get(content).pipe(map(
        data => { return data; }
      ));
    }
    else {
      return of({});;
    }
  }


  public livehealth_registerbill_post(url:string ,content: any): Observable<any> {        
    if (url != null) {     
      return this.httpHandler.external_service_post(url,content).pipe(map(
        data => { 
          return data; }
      ));
    }
    else {
      return of({});;
    }
  }

}
