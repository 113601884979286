<div class="wrapper" style="margin-top: -60px;">
    <div [formGroup]="patientInfoForm">
        <div class="row mt-5 d-flex justify-content-center">
            <div class="col-2 col-md-2 mt-1" align="right"><b>Member Id</b></div>
            <div class="col-5 col-md-3">
                <input type="text" id="membershipNo" (keypress)="NumberFieldValidate($event,9)" class="form-control"
                    [pattern]="'[789][0-9]{8}'" formControlName="membershipNo" placeholder="Member Id">
            </div>
            <div class="col-5 col-md-4">
                <button class="btn btn-success" (click)="searchPatient()" style="margin-right: 5px;">
                    <span class="icon">
                        <i class="fa fa-get-pocket"></i>
                    </span>
                    Fetch
                </button>
                <button class="btn btn-secondary" (click)="clearPatientSearch()" style="margin-right: 5px;">
                    <span class="icon">
                        <i class="fa fa-ban"></i>
                    </span>
                    Clear
                </button>
            </div>
        </div>
        <hr>

        <div class="row" [class.saloader]="loading" [ngClass]="{'disableDiv' : !enableCards}">
            <div class="col-md-3 col-12">
                <div class="card">
                    <div class="card-header  d-flex justify-content-between align-items-center">
                        <h5>Patient Information</h5>
                        <div class="float-right row">
                            <h6> <span class="badge bg-secondary"
                                    title="Membership No">{{memberInfo.membershipNo}}</span></h6>
                        </div>

                    </div>
                    <div class="card-body">
                        <div class="wrapper">
                            <div class="profile text-center">
                                <img *ngIf="patientGender === 'Male'" class="thumbnail"
                                    [src]="'assets/images/maleAvatar.jpg?ixlib=rb-0.3.5&s=9358d797b2e1370884aa51b0ab94f706&auto=format&fit=crop&w=200&q=80%20500w'" />
                                <img *ngIf="patientGender === 'Female'" class="thumbnail"
                                    [src]="'assets/images/femaleAvatar.jpg?ixlib=rb-0.3.5&s=9358d797b2e1370884aa51b0ab94f706&auto=format&fit=crop&w=200&q=80%20500w'" />
                                <h5 class="name">{{memberInfo.memberName}}</h5>
                                <p class="title">Nationality : {{memberInfo.countryName}}</p>
                                <p class="title">Identity Number : {{memberInfo.memberID}} </p>

                            </div>

                            <div class="social-icons">
                                <div class="icon">
                                    <span><i class="fa fa-group"></i></span>
                                    <h4>{{memberInfo.gender}}</h4>
                                    <p>Gender</p>
                                </div>

                                <div class="icon">
                                    <span><i class="fa fa-calendar"></i></span>
                                    <h4>{{memberInfo.memberDOB | date: 'yyyy-MM-dd'}}</h4>
                                    <p>Date of Birth</p>
                                </div>

                                <div class="icon">
                                    <span><i class="fa fa-child"></i></span>
                                    <h4>{{memberInfo.mbrAge}}</h4>
                                    <p>Age</p>
                                </div>
                            </div>

                            <div class="bullets">
                                <span><i class="fa fa-mobile" style="font-size: 15px;"></i></span>
                                {{memberInfo.mobileNo}}
                            </div>
                            <div class="bullets">
                                <span><i class="fa fa-envelope-o" style="font-size: 15px;"></i></span>
                                {{memberInfo.email}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-12 mr-4">
                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title">Test Details</h5>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="submitForm">
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label class="form-label mandatory">Request No</label>
                                        <!-- <select class="form-select" formControlName="requestNo" name="RequestNo" #rate
                                        ngModel="0">
                                        <option selected="true" value="0">Select Request No</option>
                                        <option value="123456">123456</option>
                                        <option value="456789">456789</option>
                                        <option value="789012">789012</option>
                                    </select> -->
                                        <ng-select [items]="requestNoColl" bindLabel="requestId" 
                                            placeholder="Request Number" bindValue="requestId"
                                            class="form-control" formControlName="requestNo" [clearable]="true"
                                            [searchable]="true" labelForId="ddlReqNo">
                                        </ng-select>

                                    </div>
                                </div>
                            </div>
                        </form>

                        <form [formGroup]="testForm">
                            <div class="row clearfix" width="100%">

                                <div class="col-md-10">
                                    <div class="form-group">
                                        <label class="form-label mandatory">Select Test Name</label>
                                        <ng-select [items]="labTestNameColl" bindLabel="testName" appendTo="body"
                                            bindValue="testID" class="form-control custom" formControlName="labTestId"
                                            [clearable]="true" [searchable]="true" labelForId="ddltestParent"
                                            placeholder="Test Name">
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-2 mt-5">
                                    <button class="btn btn-success" (click)="appendTest()" [disabled]="!testForm.valid">
                                        <span class="icon">
                                            <i class="fa fa-plus"></i>
                                        </span>
                                        Add
                                    </button>
                                </div>
                            </div>
                        </form>
                        <hr>

                        <div class="container mt-2" *ngIf="dynamicArray.length">
                            <div class="row">
                                <div class="col-1 ml-4 text-center"><b><u>SlNo</u></b></div>
                                <div class="col-2" style="padding-left: 15px"><b><u>Test Id</u></b></div>
                                <div class="col-7 ml-4 text-center"><b><u>Test Name</u></b></div>
                                <div class="col-2" style="padding-left: 15px"><b><u>Remove</u></b></div>
                            </div>
                            <div style="height: 340px; overflow-y: auto;">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item" *ngFor="let dynamic of dynamicArray; let i = index;">
                                        <div class="row">
                                            <div class="col-1 ">
                                                {{dynamic.slNo}}
                                            </div>
                                            <div class="col-2 ">
                                                {{dynamic.testID}}
                                            </div>
                                            <div class="col-7">
                                                {{dynamic.testName}}
                                            </div>
                                            <div class="col-2 text-center">
                                                <button class="btn btn-danger" (click)="removeTest(dynamic)">
                                                    <span class="icon">
                                                        <i class="fa fa-trash-o"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <hr style="margin-top: -1px;">
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div class="col-md-3 col-12">
                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title">Submission</h5>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="submitForm">
                            <div class="row clearfix" width="100%">
                                <!-- <div class="col-12 alert alert-info" role="alert">
                                    Home Collection Date : <b> {{memberInfo.startDate | date: 'yyyy-MM-dd mm:ss'}}</b>
                                </div> -->
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="form-label mandatory">Collection Date</label>
                                        <div class="form-group" style="margin-bottom: 0px;">

                                            <input name="hcDate" formControlName="hcDate" class="form-control"
                                                #datePicker="dpDayPicker" placeholder="DD/MM/YYYY HH:MM"
                                                [dpDayPicker]="daytimePickerConfig" [mode]="'daytime'"
                                                [theme]="'dp-main'" id="hcDate" />
                                        </div>
                                    </div>

                                </div>

                                <!-- <div class="col-12">
                                    <div class="form-group">
                                        <label class="form-label mandatory">Request No</label>
                                        <select class="form-select" formControlName="requestNo" name="RequestNo" #rate
                                            ngModel="0">
                                            <option selected value="0">Select Request No</option>
                                            <option value="123456">123456</option>
                                            <option value="456789">456789</option>
                                            <option value="789012">789012</option>
                                        </select>
                                    </div>
                                </div> -->
                                <div class="col-12 mt-2">
                                    <label class="form-label mandatory">Address</label>
                                    <textarea rows="4" style="width:100%" placeholder="Address" class="form-control"
                                        formControlName="address"></textarea>
                                </div>
                                <div class="col-12 mt-2">
                                    <label class="form-label">Comments</label>
                                    <textarea rows="4" style="width:100%" placeholder="Remarks" class="form-control"
                                        formControlName="remarks"></textarea>
                                </div>
                                <div class="col-8 mt-2"></div>
                                <div class="col-4 mt-2 text-right">
                                    <button class="btn btn-success" (click)="submitHomeCollection()"
                                        [disabled]="!dynamicArray.length">
                                        <span class="icon">
                                            <i class="fa fa-floppy-o"></i>
                                        </span>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>