import { Injectable, TemplateRef } from '@angular/core';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class SweetalertService {

  public SAVED_SUCCESS: string = "Item has been saved successfully!";
  public ACTIVATE_SUCCESS: string = "Item has been activated successfully!";
  public DEACTIVATE_SUCCESS: string = "Item has been de-activated successfully!";
  public EMPLOYEE_ALREADY_EXISTS: string = "Employee is already mapped to Synapses!";
  public RECORD_ALREADY_EXISTS: string = "Record already exists in Synapses!";
  public DELETED_SUCCESS: string = "Item has been deleted successfully!";
  public ASSIGNED_SUCCESS: string = "Ticket has been assigned to Specialist successfully!";
  public REOPENED_SUCCESS: string = "Ticket has been Re-Opened successfully!";
  public CLOSED_SUCCESS: string = "Ticket has been Closed successfully!";
  public RESOLUTION_SUCCESS: string = "Solution has been submitted successfully!";
  public EXTENSION_SAVED_SUCCESS: string = "Extension has been submitted successfully!";

  constructor() { }

  public notification_top_right(title: string, buttonText: string) {
    return Swal.fire({
      position: 'top-end',
      //position: 'bottom-end',
      title: title,
      showConfirmButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: buttonText,
      timer: 3000
    });
  }

  public alertManualClose(messageTitle: string, messageText: string) {
    Swal.fire({
      icon: 'info',
      title: messageTitle,
      text: messageText
    })
  }


  public alertAutoClose(messageText: string) {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: messageText,
      showConfirmButton: false,
      timer: 2500
    });
  }


  public deleteConfirmation() {
    return Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this item!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      //cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    });
  }

  public SubmitConfirmation(title: string, text: string) {
    return Swal.fire({
      title: title,
      text: text,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      //cancelButtonColor: '#d33',
      confirmButtonText: 'Submit'
    });
  }

  public OkCancelConfirmation(title: string, text: string) {
    return Swal.fire({
      title: title,
      text: text,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      //cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    });
  }

  public LogoutConfirmation(title: string, text: string) {
    return Swal.fire({
      title: title,
      text: text,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      //cancelButtonColor: '#d33',
      confirmButtonText: 'Log Out'
    });
  }

  public deactiveConfirmation(btnname: string) {
    return Swal.fire({
      title: 'Are you sure?',
      text: "You want to " + btnname + " this item!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      //cancelButtonColor: '#d33',
      confirmButtonText: btnname
    });
  }

  public BulkClosureConfirmation(btnname: string) {
    return Swal.fire({
      title: 'Tickets Bulk Closure completed?',
      text: "Tickets selected for closure will be cleared. Kindly make sure to discuss all selected tickets!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      //cancelButtonColor: '#d33',
      confirmButtonText: btnname
    });
  }


  public showSuccessAlert(msgTitle: string) {
    Swal.fire({
      position: 'bottom-end',
      icon: 'success',
      title: msgTitle,
      showConfirmButton: false,
      timer: 1500
    });
  }

  public showtoaster(msgTitle: string) {
    const toast = Swal.mixin({
      position: 'top-end',
      toast:true,
      background:'#ef4b4b',
      showConfirmButton: false,
      timer: 5000,    
    });

    toast.fire({title:msgTitle});
  }



  public showPopupNotes(messageText: string) {
    Swal.fire({
      title: messageText,
      showCancelButton: true,
      cancelButtonText: 'Close',
      showConfirmButton: false,
      showCloseButton: false
    })
  }



  toasts: any[] = [];

  // Push new Toasts to array with content and options
  showToast(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  // Callback method to remove Toast DOM element from view
  removeToast(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
