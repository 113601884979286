import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StatusCodeEnum } from '../models/statuscode.model';
import { ServiceResponse, ServiceResponseLiveHealth } from '../models/service-response.model';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';


``
@Injectable({
  providedIn: 'root'
})
export class HttpHandlerService {

  constructor(private http: HttpClient) { }

  // service_post(url: string, postData: any): Observable<any> {

  //   return this.http.post<any>(`${environment.apiEndPoint}` + url, postData)
  //     .pipe(map((data: ServiceResponse) => {
  //       if (data.statusCode == StatusCodeEnum.Success) {
  //         return data.result;
  //       }
  //       else { 
  //         throw data.message;
  //       }
  //     }));
  // }

  //  service_get(url: string): Observable<any> { 

  //   return this.http.get<any>(`${environment.apiEndPoint}` + url)
  //     .pipe(map((data: ServiceResponse) => {
  //       if (data.statusCode == StatusCodeEnum.Success) {
  //         return data.result;
  //       }
  //       else { 
  //         throw data.message;
  //       }
  //     }));
  // }

  // courses_service_post(url: string, postData: any): Observable<any> { 

  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/x-www-form-urlencoded'
  //     })
  //   };

  //   return this.http.post<any>(url, postData, httpOptions)
  //     .pipe(map((data: ServiceResponse) => {
  //       return data;
  //     }));
  // }

  // external_service_post(url: string, postData: any): Observable<any> {

  //   return this.http.post<any>(url, postData)
  //     .pipe(map((data: ServiceResponse) => {
  //       return data;
  //     }));
  // }


  // service_postFile(url: string, formData: FormData): Observable<any> {

  //   return this.http.post(`${environment.apiEndPoint}` + url, formData, { reportProgress: true, observe: 'events' });
  //   //return this.http.post(this.apiurlconfig.apiurl + url, formData, { reportProgress: true, observe: 'events' });
  // }


  // public downloadFile(url: string): Observable<HttpEvent<Blob>> {
  //   return this.http.request(new HttpRequest(
  //     'GET', `${environment.apiEndPoint}` + url, null,
  //    //'GET', this.apiurlconfig.apiurl + url, null,
  //     {
  //       reportProgress: true,
  //       responseType: 'blob'
  //     }));
  // }

  public getJSONData(fileUrl: string): Observable<any> {
    return this.http.get(fileUrl);
  }

  public post(url: string, postData: any, headers?: any): Observable<any> {
    return this.http.post<any>(url, postData, { headers: headers });
  }

  public service_post_ajax(url: string): Observable<any> {
    return ajax({
      crossDomain: true,
      url: url,
      method: "GET",
      responseType: "jsonp"
    });

  }

  public service_post(url: string, postData: any): Observable<any> {

    return this.http.post<any>(`${environment.apiEndPoint}` + url, postData)
      .pipe(map((data: ServiceResponse) => {
        if (data.statusCode == StatusCodeEnum.Success) {
          return data.result;
        }
        else {
          throw data.message;
        }
      }));
  }

  external_service_post(url: string, postData: any): Observable<any> {
    
    return this.http.post<any>(url, postData,
      {
        headers: new HttpHeaders()
          // .set('Content-Type', 'application/json')
          // .set('x-requested-with', 'XMLHttpRequest')
          // .set('Access-Control-Allow-Origin', '*')
          // .set('Access-Control-Allow-Methods', 'GET, POST')
          // .set('Access-Control-Allow-Headers', 'X-Requested-With'),
        //withCredentials: false,
      }).pipe(map((data: any) => {
        console.log(data);
        return data;

      }));



  }




  external_service_bupa_memberinfo_get(content: any): Observable<any> {
    return this.http.get<any>(content.url,
      {
        params: {
          membershipNo: content.membershipNo
        },
        headers: {
          'X-IBM-Client-Id': content.XIBMClientId,
          'X-IBM-Client-Secret': content.XIBMClientSecret
        }
      });

  }

  external_service_bupa_member_requests_get(content: any): Observable<any> {
    return this.http.get<any>(content.url,
      {
        params: {
          membershipNo: content.membershipNo
        },
        headers: {
          'X-IBM-Client-Id': content.XIBMClientId,
          'X-IBM-Client-Secret': content.XIBMClientSecret
        }
      });

  }

  // external_service_livehealth_testdetails_get(content: any): Observable<any> {

  //   return this.http.get<any>(content.url,
  //     {       
  //       params: {
  //         token: content.token
  //       }
  //     });

  // }

  external_service_livehealth_testdetails_get(content: any): Observable<any> {
    return this.http.get<any>('https://api.smartlab.sa/get_test/', {});
  }

  external_service_livehealth_billdetails_post(url: string, postData: any): Observable<any> {
    return this.http.post<any>(url, postData)
      .pipe(map((data: ServiceResponse) => {
        return data;
      }));

  }

}
